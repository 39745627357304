import React, { useEffect } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Banner.css";

import Video1 from "./Pirat_banner.mp4";
import Video2 from "./Giraffe.mp4";
import Video3 from "./Bulaj2.mp4";
import Video4 from "./Frida2.mp4";
import Video5 from "./Suszarnia.mp4";
import Video6 from "./Yoda.mp4";
import { AnimatedH1, AnimatedH2, AnimatedH3 } from "../Styled/StyledHeader";

export const Banner = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  const delay = 0.5;
  const duration = 1.5;

  const modifyVideoElements = () => {
    const videos = document.querySelectorAll(".slider video");
    videos.forEach((video) => {
      video.muted = true;
      video.autoplay = true;
      video.preload = "auto";
      video.controls = false;
      video.play().catch((e) => {
        console.error("Error trying to play the video:", e);
      });
    });
  };
  useEffect(() => {
    modifyVideoElements();
  }, []);

  return (
    <div className="banner-container">
      <div className="div-for-slider">
        <AutoplaySlider
          className="slider"
          play={true}
          cancelOnInteraction={false}
          onTransitionStart={modifyVideoElements}
          onFirstMount={modifyVideoElements}
          interval={5000}
          animation=""
          bullets={false}
          organicArrows={false}
          infinite={true}
          transitionDelay={500}
        >
          <div data-src={Video1} />
          <div data-src={Video2} />
          <div data-src={Video3} />
          <div data-src={Video4} />
          <div data-src={Video5} />
          <div data-src={Video6} />
        </AutoplaySlider>
      </div>

      <div className="banner-text" ref={ref}>
        <AnimatedH1 isSectionVisible={inView}>Druk ścienny</AnimatedH1>
        <AnimatedH3 isSectionVisible={inView}>
          Spraw by Twoja ściana ożyła
        </AnimatedH3>

        {inView && (
          <motion.p
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                opacity: 1,
                transition: { delay: delay, duration: duration }, // Opóźnienie dla każdego paragrafu
              },
              hidden: { opacity: 0 },
            }}
          >
            <strong>
              {" "}
              Masz w głowie wizję obrazu, grafiki, zdjęcia, logo firmy lub
              organizacji na swojej ścianie? Marzy Ci się efektowna metamorfoza
              wnętrza? Dla nas nie ma nic prostszego - wydrukujemy Twoje
              marzenia.
            </strong>
          </motion.p>
        )}
        {inView && (
          <motion.p
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                opacity: 1,
                transition: { delay: delay * 2, duration: duration },
              },
              hidden: { opacity: 0 },
            }}
          >
            Naszą specjalnością jest{" "}
            <strong>
              druk ścienny, czyli murale wykonywane przez precyzyjną maszynę.{" "}
            </strong>
            To czerpiący z najnowszej technologii proces przenoszenia obrazów
            bezpośrednio na dowolną powierzchnię.{" "}
            <strong>
              Drukarka naścienna wykorzystuje dedykowane atramenty utwardzane za
              pomocą promieni UV.{" "}
            </strong>
            Stworzony w ten sposób nadruk zachowuje swój każdy szczegół,
            wyróżnia się głębokimi, nasyconymi kolorami, trwałością i wysoką
            rozdzielczością.
          </motion.p>
        )}
        {inView && (
          <motion.p
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                opacity: 1,
                transition: { delay: delay * 3, duration: duration },
              },
              hidden: { opacity: 0 },
            }}
          >
            Naszą metodę można porównać do odbijania obrazu na papierze za
            pomocą klasycznej drukarki atramentowej. Z tą różnicą, że tutaj
            <strong>
              {" "}
              główną rolę odgrywa maszyna pracująca pionowo na każdym możliwym
              podłożu - betonie, metalu, szkle, plastiku, drewnie lub cegle!
            </strong>{" "}
            Rozmiar oraz wydajność samej drukarki pozwala na pokrywanie zarówno
            dużych powierzchni, jak i stosunkowo niewielkich pomieszczeń czy
            pozornie ograniczonych przestrzeni.
          </motion.p>
        )}
        <motion.p
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              opacity: 1,
              transition: { delay: delay * 4, duration: duration },
            },
            hidden: { opacity: 0 },
          }}
        >
          Nadruki ścienne tworzą przestrzeń, która inspiruje. Dają
          nieporównywalnie więcej możliwości i wolności niż tapety bądź
          naklejki. Dotyczy to kwestii jakości, procesu przenoszenia projektu na
          ścianę, a także jego ewentualnego usunięcia.
        </motion.p>
        <motion.p
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              opacity: 1,
              transition: { delay: delay * 5, duration: duration },
            },
            hidden: { opacity: 0 },
          }}
        >
          Jeśli chcesz oryginalnie urządzić mieszkanie, potrzebujesz wyraźnej
          identyfikacji wizualnej w biurze swojej firmy lub zależy Ci na nadaniu
          charakteru budynkom użyteczności publicznej - personalizowany druk
          ścienny to strzał w dziesiątkę!
        </motion.p>
      </div>
    </div>
  );
};
