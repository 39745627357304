import React, { useState } from "react";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { projects } from "./Gallery-data.js";

const MediaGallery = ({ setSelectedProject }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const path = process.env.PUBLIC_URL + "/Realizacje";
  const handleThumbnailClick = (project) => {
    setSelectedProject(project);
  };

  let cols = 2;
  let gap = 3;
  if (projects.length >= 2 && (small || medium)) {
    cols = 2;
    gap = 3;
  }

  if (projects.length >= 3 && (small || medium)) {
    cols = 3;
    gap = 3;
  }

  if (projects.length >= 4 && medium) {
    cols = 4;
    gap = 8;
  }

  if (projects.length >= 5 && large) {
    cols = 6;
    gap = 8;
  }

  return (
    <div className="gallery-imagelist">
      <ImageList
        cols={cols}
        gap={gap}
        sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {projects.map((project) =>
          project.thumbnails.map((thumb, index) => (
            <ImageListItem
              key={`${project.id}-${index}`}
              onClick={() => handleThumbnailClick(project)}
              sx={{
                cursor: "pointer",
                width: {
                  xs: "100px",
                  sm: "150px",
                  md: "200px",
                  opacity: "1",
                  transition: " opacity 0.3s",
                },
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <img src={thumb} alt={project.title} loading="lazy" />
              <ImageListItemBar
                title={project.title}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))
        )}
      </ImageList>
    </div>
  );
};

export default MediaGallery;
